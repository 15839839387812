import React, {memo, useCallback, useState, useEffect, useRef} from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import TextareaAutosize from 'react-textarea-autosize';
import Alert from 'react-bootstrap/Alert';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

import {
	VALIDATE_MESSAGES,
	MAGIC_OAUTH_KEY,
	MAGIC_CONNECT_NETWORK
} from '../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE
} from '../../../constants/file';
import { hideTooltip, showTooltip } from '../../../utils/input';
import {
	GENDER_LIST_WITH_DEFAULT, GENDERS,
	PRONOUNS,
	PRONOUNS_LIST_WITH_DEFAULT,
	VISIBILITY_LIST_WITH_DEFAULT
} from '../../../constants/profile';
import {checkUsernameAvailability, getDataByUsername} from '../../../services/general';
import { getAuthUserInfo } from '../../../states/general/actions';
import { registerMemberProfile } from '../../../services/user';
import UploadDragger from '../../../components/commons/UploadDragger';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { shortenNumber } from '../../../utils/number';
import routes from '../../../routes';
import { CUSTOM_REGEX } from '../../../constants/custom_regex';
import './MemberProfile.scss';
import { getListDisplay } from '../../../utils/list';
import UploadDraggerV2 from "../../../components/commons/UploadDraggerV2";
import SocialLinks from "../../../components/commons/SocialLinks";
import {updateSocialLinks} from "../../../utils/socialLinkUtils";
import {setIsNotPublicUrl, setIsPublicUrl} from "../../../states/spaceViewMode";

const RegisterMemberProfile = (props) => {
	const { verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, spaceUserName, spaceViewData } = props;
	const [form] = Form.useForm();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [initialLoad, setInitialLoad] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [formIsOnFocus, setFormIsOnFocus] = useState(false);
	const [formHasError, setFormHasError] = useState(true);
	const [alert, setAlert] = useState(null);
	const [profilePhoto, setProfilePhoto] = useState(null);
	const [profilePhotoBlobPreview, setProfilePhotoBlobPreview] = useState(null);
	const [profileVideo, setProfileVideo] = useState(null);
	const [profileVideoBlobPreview, setProfileVideoBlobPreview] = useState(null);
	const [profileBackgroundPhoto, setProfileBackgroundPhoto] = useState(null);
	const [profileBackgroundPhotoBlobPreview, setProfileBackgroundPhotoBlobPreview] = useState(null);
	const [profileBackgroundVideo, setProfileBackgroundVideo] = useState(null);
	const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] = useState(null);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [cardBackground, setCardBackground] = useState(null);
	const [cardBackgroundType, setCardBackgroundType] = useState(null);
	const [username, setUsername] = useState(null);
	const [displayName, setDisplayName] = useState(null);
	const [location, setLocation] = useState(null);
	const [bio, setBio] = useState(null);
	const [website, setWebsite] = useState(null);
	const [selectedGender, setSelectedGender] = useState('default');
	const [selectedPronouns, setSelectedPronouns] = useState('default');
	const [selectedVisibility, setSelectedVisibility] = useState('default');
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [photoBGIsRemoved, setPhotoBGIsRemoved] = useState(false);
	const [videoBGIsRemoved, setVideoBGIsRemoved] = useState(false);
    const [profileImageHasError, setProfileImageHasError] = useState(null);
    const [profileVideoHasError, setProfileVideoHasError] = useState(null);
    const [profileBGImageHasError, setProfileBGImageHasError] = useState(null);
    const [profileBGVideoHasError, setProfileBGVideoHasError] = useState(null);

	const [blurredField, setBlurredField] = useState(null);
	const [isSubmitClicked, setSubmitClicked] = useState(false);

	const gendersList = GENDERS;
	const pronounsList = PRONOUNS;
	const [usernameHasError, setUserNameHasError] = useState(null);
	const [socialLinks, setSocialLinks] = useState([]);

	const debounceTimeoutRef = useRef(null);

	useEffect(() => {
		setSocialLinks(authUserInfo?.profile?.social_links)
	}, [authUserInfo])

	if (!gendersList.find((x) => x.value === 'default')) {
		gendersList.unshift({
			label: 'Gender',
			value: 'default',
			disabled: true
		});
	}

	if (!pronounsList.find((x) => x.value === 'default')) {
		pronounsList.unshift({
			label: 'Pronouns',
			value: 'default',
			disabled: true
		});
	}

    const setProfileImageUploadErrors = (error) => {
        setProfileImageHasError(error?.message)
    }
    const setProfileVideoUploadErrors = (error) => {
        setProfileVideoHasError(error?.message)
    }
    const setProfileBGImageUploadErrors = (error) => {
        setProfileBGImageHasError(error?.message)
    }
    const setProfileBGVideoUploadErrors = (error) => {
        setProfileBGVideoHasError(error?.message)
    }

	const getSpace = useCallback(async (username) => {
			try {
				const data = {
					username: username
				};
				const response = await getDataByUsername(data);
				if (response.result) {
					if (response.is_public_view_mode) {
						dispatch(setIsPublicUrl(true));
					} else {
						dispatch(setIsNotPublicUrl(false));
					}

					spaceViewData(response.space);
				}
			} catch (error) {
				console.log(error);
			}
		}, []
	);

	const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new OAuthExtension()]
	});

	const handleFormOnBlur = useCallback(async () => {
		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
				setFormHasError(true);
			});
	}, []);

	const handleFormChanges = useCallback(async () => {
		setUsername(form.getFieldValue('username'));
		setDisplayName(form.getFieldValue('display_name'));
		setLocation(form.getFieldValue('location'));
		setBio(form.getFieldValue('bio'));

		const websiteValue = form.getFieldValue('website');
		setWebsite(websiteValue);
		const instagramValue = form.getFieldValue('instagram');
		const youtubeValue = form.getFieldValue('youtube');
		const tiktokValue = form.getFieldValue('tiktok');
		const twitchValue = form.getFieldValue('twitch');
		const facebookValue = form.getFieldValue('facebook');
		const linkedinValue = form.getFieldValue('linkedin');
		const xValue = form.getFieldValue('x');

		/* Social links onChange parser for the active indicator */
		const values = {
			website: websiteValue,
			instagram: instagramValue,
			youtube: youtubeValue,
			tiktok: tiktokValue,
			twitch: twitchValue,
			facebook: facebookValue,
			linkedin: linkedinValue,
			x: xValue
		};
		const updatedSocialLinks = updateSocialLinks(socialLinks, values);
		setSocialLinks(updatedSocialLinks);

		await form
			.validateFields()
			.then(() => {
				setFormHasError(false);
			})
			.catch((errors) => {
                // console.log(errors)
				setFormHasError(true);
			});
        
	}, [socialLinks]);

	const websiteOnBlur = (event) => {
		showTooltip(event);
		if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(event.target.value)) {
			event.target.value = 'https://' + event.target.value;
			form.setFieldValue('website', event.target.value);
		}
	};

	// const handleInputChange = (event) => {
	// 	const { name, value } = event.target;
	// 	console.log(`${name} changed: ${value}`);
	// };

	const handleSubmit = useCallback(async (values) => {
			setDisplayDropDownError(true);
			const data = values;
			//include url protocol when url protocol is missing
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.website)) {
				data.website = 'https://' + data.website;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.instagram)) {
				data.instagram = 'https://' + data.instagram;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.youtube)) {
				data.youtube = 'https://' + data.youtube;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.tiktok)) {
				data.tiktok = 'https://' + data.tiktok;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.twitch)) {
				data.twitch = 'https://' + data.twitch;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.facebook)) {
				data.facebook = 'https://' + data.facebook;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.linkedin)) {
				data.linkedin = 'https://' + data.linkedin;
			}
			if (CUSTOM_REGEX.DETECT_INVALID_PROTOCOL.test(data.x)) {
				data.x = 'https://' + data.x;
			}

           
			// if (selectedGender === 'default') {
			// 	setAlert({ variant: 'danger', message: "Please select your gender." });
			// 	return;
			// }
			// if (selectedPronouns === 'default') {
			// 	setAlert({ variant: 'danger', message: "Please select your pronouns." });
			// 	return;
			// }
			// if (selectedVisibility === 'default') {
			// 	setAlert({ variant: 'danger', message: "Please select your visibility." });
			// 	return;
			// }
			data.visibility = selectedVisibility;
			data.pronouns = selectedPronouns;
			data.gender = selectedGender;
			if (profilePhoto) {
				data.photo = profilePhoto;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}
			if (profileVideo) {
				data.video = profileVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}
			if (profileBackgroundPhoto) {
				data.background_photo = profileBackgroundPhoto;
			} else {
				if (photoBGIsRemoved) {
					data.background_photo_is_empty = true;
				}
			}
			if (profileBackgroundVideo) {
				data.background_video = profileBackgroundVideo;
			} else {
				if (videoBGIsRemoved) {
					data.background_video_is_empty = true;
				}
			}
			try {
				if (authUserInfo) {
					setSubmitted(true);
					data.user_id = authUserInfo.id;
					const resultProfile = await registerMemberProfile(data);
					if (resultProfile && resultProfile.result) {
						dispatch(getAuthUserInfo());

						if (verifyEmailSpaceUsername && verifyEmailSpaceMembershipId) {
							const url = routes.spaceMembershipActivate();
							const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
							navigate(fin_url);
						} else {
							if (authUserInfo.user_public_address) {
								navigate(routes.dashboard());
							} else {
								navigate(routes.dashboard());
							}
						}
					} else {
						setAlert({ variant: 'danger', message: resultProfile.message });
					}
					setSubmitted(false);
				}
			} catch (error) {
				setAlert({ variant: 'danger', message: error.message });
				setSubmitted(false);
			}
		},
		[
			selectedGender,
			selectedPronouns,
			selectedVisibility,
			profilePhoto,
			profileVideo,
			profileBackgroundPhoto,
			profileBackgroundVideo,
			photoIsRemoved,
			videoIsRemoved,
			photoBGIsRemoved,
			videoBGIsRemoved,
			verifyEmailSpaceUsername,
			verifyEmailSpaceMembershipId
		]
	);

	const handleProfilePhotoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setProfilePhoto(file);
		setProfilePhotoBlobPreview(blobPreview);
	};

	const handleProfileVideoUploadFileChange = useCallback(
		async (file, blobPreview) => {
			if (!file) {
				setVideoIsRemoved(true);
			} else {
				setVideoIsRemoved(false);
			}

			if (blobPreview && profileVideoBlobPreview && blobPreview !== profileVideoBlobPreview) {
				setProfileVideo(null);
				setProfileVideoBlobPreview(null);

				setTimeout(() => {
					setProfileVideo(file);
					setProfileVideoBlobPreview(blobPreview);
				});
			} else {
				setProfileVideo(file);
				setProfileVideoBlobPreview(blobPreview);
			}
		},
		[profileVideoBlobPreview]
	);

	const handleProfileBackgroundPhotoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoBGIsRemoved(true);
		} else {
			setPhotoBGIsRemoved(false);
		}

		setProfileBackgroundPhoto(file);
		setProfileBackgroundPhotoBlobPreview(blobPreview);
	};

	const handleProfileBackgroundVideoUploadFileChange = useCallback(
		async (file, blobPreview) => {
			if (!file) {
				setVideoBGIsRemoved(true);
			} else {
				setVideoBGIsRemoved(false);
			}

			if (
				blobPreview &&
				profileBackgroundVideoBlobPreview &&
				blobPreview !== profileBackgroundVideoBlobPreview
			) {
				setProfileBackgroundVideo(null);
				setProfileBackgroundVideoBlobPreview(null);

				setTimeout(() => {
					setProfileBackgroundVideo(file);
					setProfileBackgroundVideoBlobPreview(blobPreview);
				});
			} else {
				setProfileBackgroundVideo(file);
				setProfileBackgroundVideoBlobPreview(blobPreview);
			}
		},
		[profileBackgroundVideoBlobPreview]
	);

	const checkLoginRedir = async () => {
		const queryParameters = new URLSearchParams(window.location.search);
		const provider = queryParameters.get('provider') ?? 'No Provider';
		if (provider !== 'No Provider') {
			const result = await magicOauth.oauth.getRedirectResult();
			let profile = result.oauth.userInfo.profile ?? 'No Profile found';
			if (provider === 'tweeter' || provider === 'discord') {
				profile = result.oauth.userInfo.preferredUsername ?? 'No Profile found';
			}
			let email = authUserInfo.email ?? result.oauth.userInfo.email ?? 'No Email found';
			let data = {
				email: email,
				social_type: provider,
				social_link: profile
			};
			await social_link(data);
		}
	};

	const linkGoogle = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'google' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkFacebook = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'facebook' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkTweeter = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'tweeter' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const linkDiscord = async () => {
		console.log(`${window.location.origin}/callback`);
		await magicOauth.oauth.loginWithRedirect({
			provider: 'discord' /* 'google', 'facebook', 'apple', or 'github' */,
			redirectURI: `${window.location.origin}/login`
		});
	};

	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
		}
	}, [blurredField, isSubmitClicked]);

	const handleOnblurField = useCallback(async (field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);
	}, [blurredField, isSubmitClicked]);

	const setFormProfileData = async (data) => {
		if (data.profile) {
			form.setFieldsValue({
				username: data.profile.username,
				display_name: data.profile.display_name,
				location: data.profile.location,
				bio: data.profile.intro,
				website: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'website') : null,
				instagram: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'instagram') : null,
				youtube: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'youtube') : null,
				tiktok: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'tiktok') : null,
				twitch: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'twitch') : null,
				facebook: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'facebook') : null,
				linkedin: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'linkedin') : null,
				x: data.profile.social_links ? checkSocialLink(data.profile.social_links, 'x') : null
			});
			setUsername(data.profile.username);
			setDisplayName(data.profile.display_name);
			setLocation(data.profile.location);
			setBio(data.profile.intro);
			setWebsite(data.profile.url);
			if (data.profile.gender) {
				setSelectedGender(data.profile.gender);
			}
			if (data.profile.pronouns) {
				setSelectedPronouns(data.profile.pronouns);
			}
			if (data.profile.visibility.privacy) {
				setSelectedVisibility(data.profile.visibility.privacy);
			}
			if (data.profile.photo_large) {
				setProfileAssetType('image');
				setProfilePhotoBlobPreview(data.profile.photo_large);
			}
			if (data.profile.video) {
				setProfileAssetType('video');
				setProfileVideoBlobPreview(data.profile.video);
			}
			if (data.profile.background_photo) {
				setProfileBackgroundPhotoBlobPreview(data.profile.background_photo);
				const space_bg = {
					backgroundImage: 'url(' + data.profile.background_photo + ')'
				};
				setCardBackground(space_bg);
				setCardBackgroundType('image');
			}
			if (data.profile.background_video) {
				setProfileBackgroundVideoBlobPreview(data.profile.background_video);
				setCardBackgroundType('video');
			}
		}
	};

	const updateProfilePhotoAsset = async (photo, video, bg_photo, bg_video) => {
		if (!video && photo) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(photo);
		}
		if (video) {
			setProfileAssetType('video');
			setProfileVideoBlobPreview(video);
		}
		if (!video && !photo) {
			setProfileAssetType('image');
			setProfilePhotoBlobPreview(null);
			setProfileVideoBlobPreview(null);
		}
		if (!bg_video && bg_photo) {
			setProfileBackgroundPhotoBlobPreview(bg_photo);
			const space_bg = {
				backgroundImage: 'url(' + bg_photo + ')'
			};
			setCardBackground(space_bg);
			setCardBackgroundType('image');
		}
		if (bg_video) {
			setProfileBackgroundVideoBlobPreview(bg_video);
			setCardBackgroundType('video');
		}
		if (!bg_video && !bg_photo) {
			setCardBackgroundType('image');
			setCardBackground(null);
			setProfileBackgroundVideoBlobPreview(null);
		}
	};

	const handleUsernameValidation = (rule, value) => {
		return new Promise((resolve, reject) => {
			if (debounceTimeoutRef.current) {
				clearTimeout(debounceTimeoutRef.current);
			}

			debounceTimeoutRef.current = setTimeout(async () => {
				if (!value) {
					setUserNameHasError('Username is required.');
					return reject('Username is required.');
				}
				const data = {
					current_user_id: authUserInfo.id,
					username: value
				};

				try {
					const response = await checkUsernameAvailability(data);
					const available = response.result ? response.availability : false;

					if (!available) {
						setUserNameHasError('Username not available.');
						return reject('Username not available.');
					} else {
						setUserNameHasError(null);
					}
				} catch (error) {
					setUserNameHasError('Error checking username availability.');
					return reject('Error checking username availability.');
				}
				if (value.match(/\ /g) || /[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(value)) {
					setUserNameHasError('Invalid Input');
					return reject('Invalid Input');
				} else {
					setUserNameHasError(null);
				}

				return resolve();
			}, 1000);
		});
	};

	const checkSocialLink = (socialLinks, socialLinkType) => {
		let active = null
		socialLinks.map((socialLink)=>{
			if (socialLink.social_type === socialLinkType && socialLink.social_link && socialLink.social_link?.trim() !== null)
				active = socialLink.social_link
		})
		return active
	};

	useEffect(() => {}, [profileImageHasError, profileVideoHasError, profileBGImageHasError, profileBGVideoHasError])

	useEffect(() => {
		if (loading && loading === 'done' && authUserInfo && initialLoad) {
			setInitialLoad(false);
			setFormProfileData(authUserInfo).then((r) => {});
			if (spaceUserName) {
				getSpace(spaceUserName).then(r => {});
			}
		}
		document.title = 'Commonspace Member Profile';
		checkLoginRedir().then(r => {});
		if (!initialLoad) {
			updateProfilePhotoAsset(
				profilePhotoBlobPreview,
				profileVideoBlobPreview,
				profileBackgroundPhotoBlobPreview,
				profileBackgroundVideoBlobPreview
			);
		}
	}, [
		loading,
		authUserInfo,
		initialLoad,
		socialLinks,
		profilePhotoBlobPreview,
		profileVideoBlobPreview,
		profileBackgroundPhotoBlobPreview,
		profileBackgroundVideoBlobPreview
	]);

	return (
		<>
            <main id="cs-space-main" className="cs-main main-theme">
                <section id="cs-space-profile-create-c1" className="p-section-safeview theme-transparent">
                    <div className="centerframe-grid gap-2gutter">
                        <div id="cs-profile-create-main" className="block-lg order-1">
                            <hgroup className="heading">
                                <h1>Create your Profile</h1>
                                <p className="text-p2">Your profile will be visible to friends, community members, and the public, based on your preferences. Images and videos are optional.</p>
                            </hgroup>
							<Form
								form={form}
								name="memberProfileForm"
								validateMessages={VALIDATE_MESSAGES}
								onFocus={() => {
									setFormIsOnFocus(true);
								}}
								onBlur={() => {
									setFormIsOnFocus(false);
									handleFormOnBlur().then(r => {});
								}}
								onChange={handleFormChanges}
								onFinish={handleSubmit}
								onFinishFailed={handleFinishFailed}
								className={"stack-col" + (formIsOnFocus ? ' hide-antd-error-messages' : '')}
							>
								<div className="cs-stack-form-media stack-row">
									<div className="cs-stack-form-media-item">
										<UploadDraggerV2
											cropperAspectRatio={1 / 1}
											shape="circle"
											hideDragIcon="true"
											onError={setProfileImageUploadErrors}
											hideText="true"
											uploadFileTypes={IMAGE_FILETYPES.toString()}
											uploadedFilePreview={profilePhotoBlobPreview}
											onUploadFileChange={handleProfilePhotoUploadFileChange}
											minHeight={IMAGE_STANDARD_MIN_HEIGHT}
											minWidth={IMAGE_STANDARD_MIN_WIDTH}
											maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
											maxWidth={IMAGE_STANDARD_MAX_WIDTH}
											uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
											disableCropper={true}
										/>
										<p className="cs-label">Profile Image</p>
										<p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>
										{profileImageHasError && (
											<div className="alert callout warning items-center">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{profileImageHasError}</h6>
													</div>
												</div>

											</div>
										)}
									</div>

									<div className="cs-stack-form-media-item">
										<UploadDraggerV2
											shape="circle"
											hideDragIcon="true"
											hideText="true"
											onError={setProfileVideoUploadErrors}
											uploadFileTypes={VIDEO_FILETYPES.toString()}
											uploadedFilePreview={profileVideoBlobPreview}
											onUploadFileChange={handleProfileVideoUploadFileChange}
											minHeight={VIDEO_STANDARD_MIN_HEIGHT}
											minWidth={VIDEO_STANDARD_MIN_WIDTH}
											maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
											maxWidth={VIDEO_STANDARD_MAX_WIDTH}
											uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
										/>
										<p className="cs-label">Profile Video</p>
										<p className="cs-helper">Up to 1920px/1080p square preferred MOV or MP4</p>
										{profileVideoHasError && (
											<div className="alert callout warning items-center">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{profileVideoHasError}</h6>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="cs-stack-form-media stack-row">
									<div className="cs-stack-form-media-item">
										<UploadDraggerV2
											cropperAspectRatio={16 / 9}
											hideDragIcon="true"
											hideText="true"
											onError={setProfileBGImageUploadErrors}
											uploadFileTypes={IMAGE_FILETYPES.toString()}
											uploadedFilePreview={profileBackgroundPhotoBlobPreview}
											onUploadFileChange={
												handleProfileBackgroundPhotoUploadFileChange
											}
											minHeight={IMAGE_LARGE_MIN_HEIGHT}
											minWidth={IMAGE_LARGE_MIN_WIDTH}
											maxHeight={IMAGE_LARGE_MAX_HEIGHT}
											maxWidth={IMAGE_LARGE_MAX_WIDTH}
											uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
											disableCropper={true}
											shape="aspect-cs-preview"
										/>
										<p className="cs-label">Background Image</p>
										<p className="cs-helper">Up to 3840px square or wide JPEG, PNG, or GIF</p>
										{profileBGImageHasError && (
											<div className="alert callout warning items-center">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{profileBGImageHasError}</h6>
													</div>
												</div>

											</div>
										)}
									</div>

									<div className="cs-stack-form-media-item">
										<UploadDraggerV2
											hideDragIcon="true"
											hideText="true"
											onError={setProfileBGVideoUploadErrors}
											uploadFileTypes={VIDEO_FILETYPES.toString()}
											uploadedFilePreview={profileBackgroundVideoBlobPreview}
											onUploadFileChange={
												handleProfileBackgroundVideoUploadFileChange
											}
											minHeight={VIDEO_STANDARD_MIN_HEIGHT}
											minWidth={VIDEO_STANDARD_MIN_WIDTH}
											maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
											maxWidth={VIDEO_STANDARD_MAX_WIDTH}
											uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
											shape="aspect-cs-preview"
										/>
										<p className="cs-label">Background Video</p>
										<p className="cs-helper">Up to 1920px/1080p square or wide MOV or MP4</p>
										{profileBGVideoHasError && (
											<div className="alert callout warning items-center">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{profileBGVideoHasError}</h6>
													</div>
												</div>

											</div>
										)}
									</div>
								</div>
								<div className="form-input air">
									<label htmlFor="username">Username</label>
									{/*<input type="text" name="username" id="username" autoComplete="username" required*/}
									{/*	   placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only."/>*/}
									<Form.Item
										label="Username"
										name="username"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{ required: true },
											{
												min: 2,
												message: 'Must be minimum 2 characters.'
											},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											},
											{validator: handleUsernameValidation}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={32}
											onBlur={()=>handleOnblurField('username')}
											disabled={submitted}
											placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only."
										/>
									</Form.Item>
									{((blurredField === 'username' || blurredField === 'all') && (usernameHasError !== null || form.getFieldsError()[0]?.errors.length > 0)) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{usernameHasError !== null ? usernameHasError : form.getFieldsError()[0]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="displayname">Display Name</label>
									<Form.Item
										label="Display Name"
										name="display_name"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{ required: true },
											{
												max: 64,
												message: 'Must not exceed 64 characters.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={64}
											disabled={submitted}
											onBlur={()=>handleOnblurField('display_name')}
											placeholder="Up to 64 characters."
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'display_name' || blurredField === 'all') && form.getFieldsError()[1]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[1]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="location">Location</label>
									<Form.Item
										label="Location"
										name="location"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={32}
											disabled={submitted}
											onBlur={()=>handleOnblurField('location')}
											placeholder="Up to 32 characters. For Profile display only, separate from private City."
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'location' || blurredField === 'all') && form.getFieldsError()[2]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[2]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-textarea air">
									<label htmlFor="shortbio">Short Bio</label>
									<Form.Item
										label="Short Bio"
										name="bio"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 255,
												message: 'Must not exceed 255 characters.'
											}
										]}
									>
										<FormFieldStacked
											formType='textarea'
											type="text"
											maxLength={255}
											disabled={submitted}
											onBlur={()=>handleOnblurField('bio')}
											placeholder="Up to 255 characters, including spaces."
											rows={3}
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'bio' || blurredField === 'all') && form.getFieldsError()[3]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[3]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="website">Website</label>
									<Form.Item
										label="Website"
										name="website"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.'
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('website')}
											placeholder="site.com"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'website' || blurredField === 'all') && form.getFieldsError()[4]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[4]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="instagram">Instagram</label>
									<Form.Item
										label="Instagram"
										name="instagram"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.'
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('instagram')}
											placeholder="instagram.com/username"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'instagram' || blurredField === 'all') && form.getFieldsError()[5]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[5]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="youtube">Youtube</label>
									<Form.Item
										label="Youtube"
										name="youtube"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.'
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('youtube')}
											placeholder="youtube.com/@handle"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'youtube' || blurredField === 'all') && form.getFieldsError()[6]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[6]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="tiktok">TikTok</label>
									<Form.Item
										label="TikTok"
										name="tiktok"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.'
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('tiktok')}
											placeholder="tiktok.com/@username"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'tiktok' || blurredField === 'all') && form.getFieldsError()[7]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[7]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="twitch">Twitch</label>
									<Form.Item
										label="Twitch"
										name="twitch"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.'
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('twitch')}
											placeholder="twitch.tv/username"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'twitch' || blurredField === 'all') && form.getFieldsError()[8]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[8]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="facebook">Facebook</label>
									<Form.Item
										label="Facebook"
										name="facebook"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.',
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('facebook')}
											placeholder="fb.com/username"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'facebook' || blurredField === 'all') && form.getFieldsError()[9]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[9]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="linkedin">LinkedIn</label>
									<Form.Item
										label="LinkedIn"
										name="linkedin"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.'
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('linkedin')}
											placeholder="linkedin.com/in/username"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'linkedin' || blurredField === 'all') && form.getFieldsError()[10]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[10]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="form-input air">
									<label htmlFor="x">X</label>
									<Form.Item
										label="X"
										name="x"
										validateTrigger="onBlur"
										className="w-full"
										rules={[
											{
												max: 100,
												message: 'Must not exceed 100 characters.'
											},
                                            {
												pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
												message: 'Please enter a valid URL.'
											}
										]}
									>
										<FormFieldStacked
											formType='input'
											type="text"
											maxLength={100}
											disabled={submitted}
											onBlur={()=>handleOnblurField('x')}
											placeholder="x.com/username"
										></FormFieldStacked>
									</Form.Item>
									{((blurredField === 'x' || blurredField === 'all') && form.getFieldsError()[11]?.errors.length > 0) && (
										<div className="alert callout warning">
											<div className="action">
												<i className="fa-solid fa-triangle-exclamation"></i>
											</div>
											<div className="content">
												<div>
													<h6>{form.getFieldsError()[11]?.errors}</h6>
												</div>
											</div>
										</div>
									)}
								</div>
								{/*<div className="cs-stack-form-link-icons">*/}
								{/*	<p>Links</p>*/}
								{/*	<div className="cs-stack-link-icons stack-row-wrap">*/}
								{/*		<a href="https://x.com" target="_blank"*/}
								{/*		   className="button-icon-round-gradient-alt active"><span><p><i*/}
								{/*			className="fa-brands fa-x-twitter"></i></p></span></a>*/}
								{/*		<a href="https://instagram.com" target="_blank"*/}
								{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
								{/*			className="fa-brands fa-instagram"></i></p></span></a>*/}
								{/*		<a href="https://facebook.com" target="_blank"*/}
								{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
								{/*			className="fa-brands fa-facebook-f"></i></p></span></a>*/}
								{/*		<a href="https://tiktok.com" target="_blank"*/}
								{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
								{/*			className="fa-brands fa-tiktok"></i></p></span></a>*/}
								{/*		<a href="https://linkedin.com" target="_blank"*/}
								{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
								{/*			className="fa-brands fa-linkedin-in"></i></p></span></a>*/}
								{/*		<a href="https://twitch.tv" target="_blank"*/}
								{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
								{/*			className="fa-brands fa-twitch"></i></p></span></a>*/}
								{/*		<a href="https://google.com" target="_blank"*/}
								{/*		   className="button-icon-round-gradient-alt"><span><p><i*/}
								{/*			className="fa-regular fa-globe"></i></p></span></a>*/}
								{/*	</div>*/}
								{/*</div>*/}

								<div className="cs-stack-form-buttons">
									<div className="cs-stack-form-buttons-set">
										<DropdownWithItems
											title="Gender"
											customButtonClass="icon-dropdown-gradient-alt secondary"
											icon="fa-light fa-person-half-dress"
											selectedValue={selectedGender}
											listItems={gendersList}
											onDropdownChange={setSelectedGender}
										/>
										<DropdownWithItems
											title="Pronouns"
											customButtonClass="icon-dropdown-gradient-alt secondary"
											icon="fa-light fa-user"
											selectedValue={selectedPronouns}
											listItems={pronounsList}
											onDropdownChange={setSelectedPronouns}
										/>
										<DropdownWithItems
											title="Visibility"
											customButtonClass="icon-dropdown-gradient-alt secondary"
											icon="fa-light fa-eye"
											selectedValue={selectedVisibility}
											listItems={VISIBILITY_LIST_WITH_DEFAULT}
											onDropdownChange={setSelectedVisibility}
										/>
									</div>
								</div>
								{alert && (

									<section className={"alert callout warning items-center px-section-safeview" + (alert.variant === 'success' ? ' success' : ' error')}>
										<div className="action">
											<i className="fa-solid fa-circle-check"></i>
										</div>
										<div className="content">
											<hgroup>
												<h6>{alert.message}</h6>
												{/*<p>{alert.message}</p>*/}
											</hgroup>
											{/*<a href="#alertlink" class="button">Link Button</a>*/}
										</div>
										<div className="action">
											<a onClick={() => {setAlert(null)}}>
												<i className="fa-solid fa-xmark"></i>
											</a>
										</div>
									</section>
								)}
								<Form.Item className="">
									<button disabled={submitted} type="submit" className={`w-full` + (submitted ? ' pointer-events-none' : ' pointer-events-auto')}>
										<a
											className="button-primary-gradient w-full"
										>
											<span>
												<p>
													{submitted && (
														<i className="fa-solid fa-spinner fa-spin  mr-2"></i>
													)}
													Save Profile
												</p>
											</span>
										</a>
									</button>
								</Form.Item>
							</Form>
                        </div>
                        <aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block relative" >
                            <div className="cs-profile-preview card sticky top-2gutter-xl" style={cardBackground}>
                                {cardBackgroundType && cardBackgroundType === 'video' && profileBackgroundVideoBlobPreview && (
									<video
										autoPlay
										muted
										loop
										className="video-profile-background curve absolute"
									>
										<source
											src={profileBackgroundVideoBlobPreview}
											type="video/mp4"
										/>
									</video>
								)}
								<div className="cs-stack-profile-preview stack-col relative">
									<div className="form-input-media lg circle air nohover overflow-hidden" >
										{profileAssetType && profileAssetType === 'video' ? (
											<div className="video-profile-background-container">
												<video
													autoPlay
													muted
													loop
													className="video-profile-avatar"
												>
													<source
														src={profileVideoBlobPreview}
														type="video/mp4"
													/>
												</video>
											</div>
										) : (
											profilePhotoBlobPreview && (
												<img
													className="h-full w-full"
													src={profilePhotoBlobPreview}
												/>
											)
										)}
									</div>
									<hgroup className='z-index-ontop'>
										<p className="text-p1 active">@{username ? username : 'username'}</p>
										<h1>{displayName ? displayName : 'Display Name'}</h1>
										<p className="text-p2 italic">{selectedPronouns
											? getListDisplay(PRONOUNS, selectedPronouns)
											: 'Pronouns'}</p>
									</hgroup>
									<p className="italic z-index-ontop">{bio ? bio : 'Short Bio goes here'}</p>
									{(authUserInfo?.profile) && (
										<SocialLinks
											socialLinks={socialLinks}
											parentClassName="cs-stack-icons stack-row-wrap"
											childClassName=""
											removeATag={true}
										/>
									)}
									{/*<div className="cs-stack-icons stack-row-wrap z-index-ontop">*/}
									{/*	<i className="fa-brands fa-x-twitter"></i>*/}
									{/*	<i className="fa-brands fa-instagram active"></i>*/}
									{/*	<i className="fa-brands fa-tiktok"></i>*/}
									{/*	<i className="fa-brands fa-linkedin-in"></i>*/}
									{/*	<i className="fa-brands fa-twitch"></i>*/}
									{/*	<i className="fa-regular fa-globe"></i>*/}
									{/*</div>*/}
								</div>
                            </div>
                        </aside>
                    </div>
                </section>
            </main> 

			{/* <main id="member-profile" >
				<div className="container">
					<div className="flex justify-between grid grid-cols-1 lg:grid-cols-2 mt-8 gap-8">
						<div>
							<h1 className="mb-4 mb-8 text-5xl font-['din2014Bold'] gradient-color-txt">
								{authUserInfo &&
								authUserInfo.profile &&
								authUserInfo.profile.username
									? 'Update'
									: 'Create'}{' '}
								your Profile
							</h1>
							<h5 className="body-text--reg">
								Your profile will be visible to friends, community members, and the
								public, based on your preferences.
							</h5>
							<div className="grid grid-cols-12 md:gap-8 mb-8">
								<div className="col-span-6">
									<div className="upload-circ no-bg">
										<UploadDragger
											cropperAspectRatio={1 / 1}
											circular="true"
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={IMAGE_FILETYPES.toString()}
											uploadedFilePreview={profilePhotoBlobPreview}
											onUploadFileChange={handleProfilePhotoUploadFileChange}
											minHeight={IMAGE_STANDARD_MIN_HEIGHT}
											minWidth={IMAGE_STANDARD_MIN_WIDTH}
											maxHeight={IMAGE_STANDARD_MAX_HEIGHT}
											maxWidth={IMAGE_STANDARD_MAX_WIDTH}
											uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}
											disableCropper={true}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Profile Image
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 1920px, square preferred
										<br />
										JPEG, PNG, or GIF
									</p>
								</div>
								<div className="col-span-6">
									<div className="upload-circ no-bg">
										<UploadDragger
											circular="true"
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={VIDEO_FILETYPES.toString()}
											uploadedFilePreview={profileVideoBlobPreview}
											onUploadFileChange={handleProfileVideoUploadFileChange}
											minHeight={VIDEO_STANDARD_MIN_HEIGHT}
											minWidth={VIDEO_STANDARD_MIN_WIDTH}
											maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
											maxWidth={VIDEO_STANDARD_MAX_WIDTH}
											uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Profile Video
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 1920px/1080p, square preferred
										<br />
										MOV or MP4
									</p>
								</div>
							</div>
							<div className="grid grid-cols-12 md:gap-8 mb-8">
								<div className="col-span-12 md:col-span-6">
									<div className="upload-rec no-bg">
										<UploadDragger
											cropperAspectRatio={16 / 9}
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={IMAGE_FILETYPES.toString()}
											uploadedFilePreview={profileBackgroundPhotoBlobPreview}
											onUploadFileChange={
												handleProfileBackgroundPhotoUploadFileChange
											}
											minHeight={IMAGE_LARGE_MIN_HEIGHT}
											minWidth={IMAGE_LARGE_MIN_WIDTH}
											maxHeight={IMAGE_LARGE_MAX_HEIGHT}
											maxWidth={IMAGE_LARGE_MAX_WIDTH}
											uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
											disableCropper={true}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Background Image
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 3840px, square preferred
										<br />
										JPEG, PNG, or GIF
									</p>
								</div>
								<div className="col-span-12 md:col-span-6">
									<div className="upload-rec no-bg">
										<UploadDragger
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={VIDEO_FILETYPES.toString()}
											uploadedFilePreview={profileBackgroundVideoBlobPreview}
											onUploadFileChange={
												handleProfileBackgroundVideoUploadFileChange
											}
											minHeight={VIDEO_STANDARD_MIN_HEIGHT}
											minWidth={VIDEO_STANDARD_MIN_WIDTH}
											maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
											maxWidth={VIDEO_STANDARD_MAX_WIDTH}
											uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Background Video
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 1920px/1080p, square or wide
										<br />
										MOV or MP4
									</p>
								</div>
							</div>
							<Form
								form={form}
								name="memberProfileForm"
								validateMessages={VALIDATE_MESSAGES}
								onFocus={() => {
									setFormIsOnFocus(true);
								}}
								onBlur={() => {
									setTimeout(() => {
										setFormIsOnFocus(false);
										handleFormOnBlur();
									}, 100);
								}}
								onChange={handleFormChanges}
								onFinish={handleSubmit}
								className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
							>
								<div className="grid grid-cols-12 md:gap-8">
									<div className="col-span-12">
										<div className="mb-8">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">
												Username*
											</label>
											<div className="relative  rounded-none ">
											<Form.Item
													label="Username"
													name="username"
													className="mb-0 hidden-label"
													validateTrigger="onBlur"
													rules={[
														{ required: true },
														{
															min: 2,
															message: 'Must be minimum 2 characters.'
														},
														{
															max: 32,
															message: 'Must not exceed 32 characters.'
														},
														({ getFieldValue }) => ({
															async validator(_, value) {
																// TODO: find way to trigger username API check on form submit to avoid flood requests
																// if (!formIsOnFocus && value) {
																if (value) {
																	const data = {
																		current_user_id:
																			authUserInfo.id,
																		username: username
																	};

																	let available = false;
																	const response =
																		await checkUsernameAvailability(
																			data
																		);
																	if (response && response.result) {
																		available =
																			response.availability;
																	}

																	if (!available) {
																		return Promise.reject(
																			new Error(
																				'Username not available.'
																			)
																		);
																	}
																}

																if (
																	value.match(/\ /g) ||
																	/[`!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/.test(
																		value
																	)
																) {
																	return Promise.reject(
																		new Error('Invalid Input')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>												
												<FormFieldStacked
												formType='input'
												type="text"
												maxLength={32}
												disabled={submitted}
												placeholder="2 to 32 characters. Letters, numbers, periods, and underscores only."
												></FormFieldStacked>
												</Form.Item>
											</div>
											
										</div>
										<div className="mb-8">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">
												Display Name*
											</label>
											<div className="relative  rounded-none ">
											<Form.Item
													label="Display Name"
													name="display_name"
													className="mb-0 hidden-label"
													validateTrigger="onBlur"
													rules={[
														{ required: true },
														{
															max: 32,
															message: 'Must not exceed 32 characters.'
														}
													]}
												>										
													<FormFieldStacked
													formType='input'
													type="text"
													maxLength={32}
													disabled={submitted}
													placeholder="Up to 32 characters."
													></FormFieldStacked>
												</Form.Item>
											</div>
											
										</div>
										<div className="mb-8">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">
												Location
											</label>
											<div className="relative  rounded-none ">
												<Form.Item
													label="Location"
													name="location"
													className="mb-0 hidden-label"
													validateTrigger="onBlur"
													rules={[
														{
															max: 32,
															message: 'Must not exceed 32 characters.'
														}
													]}
												>
													<FormFieldStacked
													formType='input'
													type="text"
													maxLength={32}
													disabled={submitted}
													placeholder="Up to 32 characters. This displays on your Profile and is separate from your private City location."
													></FormFieldStacked>
												</Form.Item>
											</div>
											
										</div>
										<div className="mb-8">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">
												Bio
											</label>
											<div className="relative  rounded-none ">
												<Form.Item
													label="Bio"
													name="bio"
													className="mb-0 hidden-label"
													validateTrigger="onBlur"
													rules={[
														{
															max: 255,
															message: 'Must not exceed 255 characters.'
														}
													]}
												>
												<FormFieldStacked
												formType='textarea'
												type="text"
												maxLength={255}
												disabled={submitted}
												placeholder="Up to 255 characters."
												></FormFieldStacked>
												</Form.Item>
											</div>
											
										</div>
										<div className="mb-8">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2 mb-2">
												Website	
											</label>
											<div className="relative  rounded-none ">
												<Form.Item
													label="Website"
													name="website"
													className="mb-0 hidden-label"
													onBlur={websiteOnBlur}
													validateTrigger="onBlur"
													rules={[
														{
															// type: 'url',
															pattern: CUSTOM_REGEX.IDENTIFY_VALID_URL,
															message: 'Please Enter a valid URL'
														}
													]}
												>			
												<FormFieldStacked
												formType='input'
												type="text"
												disabled={submitted}
												placeholder="www.domain.com">											
												</FormFieldStacked>
												</Form.Item>
											</div>
											
										</div>
										
										
										<div className=" mb-8">
											<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
												Socials
											</label>
											<ul className="list-socials grid grid-cols-4 sm:grid-cols-8">
												<li>
													<i
														className="fa-brands fa-twitter"
													></i>
												</li>
												<li>
													<i className="fa-brands fa-instagram"></i>
												</li>
												<li>
													<i
														className="fa-brands fa-facebook"
													></i>
												</li>
												<li>
													<i className="fa-brands fa-tiktok"></i>
												</li>
												<li>
													<i
														className="fa-brands fa-youtube"
													></i>
												</li>
												<li>
													<i className="fa-brands fa-linkedin"></i>
												</li>
												<li>
													<i
														className="fa-brands fa-discord"
													></i>
												</li>
												<li>
													<i className="fa-brands fa-twitch"></i>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="grid grid-cols-12 md:gap-8">
									<div className="col-span-6 xl:col-span-4 mb-8">
										<DropdownWithItems
											title="Gender"
											customButtonClass="btn-lg btn-secondary w-full   h-[40px] rounded-full text-base cursor-pointer  z-50  text-white flex place-content-around mx-auto px-[2px] py-[2px]"
											icon="fa-regular fa-person-half-dress gradient-color-txt pl-[15px] !text-base"
											selectedValue={selectedGender}
											listItems={GENDER_LIST_WITH_DEFAULT}
											onDropdownChange={setSelectedGender}
										/>
									</div>
									<div className="col-span-6 xl:col-span-4 mb-8">
										<DropdownWithItems
											title="Pronouns"
											customButtonClass="btn-lg btn-secondary w-full  h-[40px] rounded-full text-base cursor-pointer  z-50  text-white flex place-content-around mx-auto px-[2px] py-[2px]"
											icon="fa-regular fa-user gradient-color-txt pl-[15px] !text-base"
											selectedValue={selectedPronouns}
											listItems={PRONOUNS_LIST_WITH_DEFAULT}
											onDropdownChange={setSelectedPronouns}
										/>
									</div>
									<div className="col-span-12 xl:col-span-4 mb-8">
										<DropdownWithItems
											title="Visibility"
											customButtonClass="btn-lg btn-secondary w-full  h-[40px] rounded-full text-base cursor-pointer  z-50  text-white flex place-content-around mx-auto px-[2px] py-[2px]"
											displayError={
												displayDropDownError &&
												selectedVisibility === 'default'
											}
											icon="fa-light fa-eye gradient-color-txt pl-[15px] !text-base"
											selectedValue={selectedVisibility}
											listItems={VISIBILITY_LIST_WITH_DEFAULT}
											onDropdownChange={setSelectedVisibility}
										/>
									</div>
								</div>
								<>
									{alert && (
										<div className="grid grid-cols-12 md:gap-8 my-2 mb-8">
											<div className="col-span-12">
												<Alert
													key={alert.variant}
													variant={alert.variant}
													className={
														'custom-alert ' +
														(alert.variant === 'danger'
															? 'custom-alert-danger'
															: 'custom-alert-success')
													}
												>
													{alert.message}
												</Alert>
											</div>
										</div>
									)}
								</>
								<Form.Item>
									<Button
										className="btn btn-lg border-filled mb-8 mb-3 text-white"
										type="submit"
										disabled={submitted || formHasError}
									>
										{submitted && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										<span className='!text-xl'>Save Profile</span>
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div className="flex items-center">
							<div className="profile-card mt-4" style={cardBackground}>
								{cardBackgroundType &&
									cardBackgroundType === 'video' &&
									profileBackgroundVideoBlobPreview && (
										<video
											autoPlay
											muted
											loop
											className="video-profile-background curve"
										>
											<source
												src={profileBackgroundVideoBlobPreview}
												type="video/mp4"
											/>
										</video>
									)}
								<div className="z-index-ontop">
									{profileAssetType && profileAssetType === 'video' ? (
										<div className="video-profile-background-container">
											<video
												autoPlay
												muted
												loop
												className="video-profile-avatar"
											>
												<source
													src={profileVideoBlobPreview}
													type="video/mp4"
												/>
											</video>
										</div>
									) : (
										<img
											className="imagePreview"
											src={profilePhotoBlobPreview}
										/>
									)}
									<h5 className="body-text--reg mt-4">
										@{username ? username : 'username'}
									</h5>
									<h1 className="header-large pb-3">
										{displayName ? displayName : 'Display Name'}
									</h1>
									<p className="body-txtitalic--small">
										{selectedPronouns
											? getListDisplay(PRONOUNS, selectedPronouns)
											: 'Pronouns'}
									</p>
									<p className="py-4 px-5 body-txtitalic--smallest">
										{bio ? bio : 'Short Bio goes here'}
									</p>
									<p className="body-txtitalic--small mb-8">
										{location ? location : 'Location'}
									</p>
									<ul className="list-socials grid grid-cols-4 sm:grid-cols-8 ">
										<li>
											{/* <a href=""> */}
											
										{/* <li>
											<i className="fa-regular fa-globe  isDisabled"></i>
										</li> */}
									{/* </ul>
									<div className="grid grid-cols-12 md:gap-8 mt-8 pt-4">
										<div className='col-start-1 col-span-3'>
											<p className="body-text--large mb-0">
												{shortenNumber(
													authUserInfo ? authUserInfo.followers_count : 0
												)}
											</p>
											<p className="body-text--smallest">Followers</p>
										</div>
										<div className='col-start-4 col-span-3'>
											<p className="body-text--large mb-0">
												{shortenNumber(
													authUserInfo ? authUserInfo.mutuals_count : 0
												)}
											</p>
											<p className="body-text--smallest">Following</p>
										</div>
										<div className='col-start-7 col-span-3'>
											<p className="body-text--large mb-0">
												{shortenNumber(
													authUserInfo ? authUserInfo.spaces_count : 0
												)}
											</p>
											<p className="body-text--smallest">Spaces</p>
										</div>
										<div className='col-start-10 col-span-3'>	
											<p className="body-text--large mb-0">
												{shortenNumber(
													authUserInfo
														? authUserInfo.collections_count
														: 0
												)}
											</p>
											<p className="body-text--smallest">Items</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>  */}
		</>
	);
};
export default memo(RegisterMemberProfile);
