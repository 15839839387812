import React, {memo, useCallback, useEffect, useState} from 'react';
import {Form} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getSpaceInfo} from '../../../states/spaceGlobal/actions';
import routes from '../../../routes';
import {getMembershipDetails, getSpaceMembership} from '../../../services/space';
import '../../SpaceBuyMembership/SpaceBuyMembership.scss'
import {MembershipItemUtils} from "../../../utils/MembershipItemUtils";
import {checkActiveMembershipStatus, checkMembershipItemStatus, updateMembershipItemStatus} from "../../../services/payment_method";
import {VALIDATE_MESSAGES} from "../../../constants/common";
import FormFieldStacked from "../../../components/commons/FormFieldStacked";
import CreateMemberProfile from "../../../components/commons/CreateMemberProfile";
import {getLocalStorage, setLocalStorage} from "../../../utils/storage";
import SocialLinks from "../../../components/commons/SocialLinks";
import {answerMemberQuestion, getMemberQuestions} from "../../../services/user";

const MembershipActivateWalletConnect = (props) => {
    const {verifyEmailSpaceUsername, verifyEmailSpaceMembershipId} = props;
    const currentLocation = useLocation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);
    const [isSubmitted, setSubmitted] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isActivationOpen, setActivationOpen] = useState(false);
    const [membershipDetails, setMembershipDetails] = useState(false);
    const [isSwitchMembership, setIsSwitchMembership] = useState(false);
    const [isCheckStatusLoaded, setCheckStatusLoaded] = useState(false);
    const [isNewAccountMembership, setIsNewAccountMembership] = useState(false);
    const [isKeepActiveMembership, setIsKeepActiveMembership] = useState(false);
    const [isMembershipDetailsLoaded, setMembershipDetailsLoaded] = useState(false);
    const [isQuestionsLoaded, setIsQuestionLoaded] = useState(false);

    const [membership, setMembership] = useState(null);

    const [collectionAssetType, setCollectionAssetType] = useState('image');

    const [socialLinks, setSocialLinks] = useState([]);

    const [memberDefaultQuestions, setMemberDefaultQuestions] = useState([]);
    const [memberQuestions, setMemberQuestions] = useState([]);
    // const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);

    const [formHasError, setFormHasError] = useState(true);
    const [blurredField, setBlurredField] = useState(null);
    const [isSubmitClicked, setSubmitClicked] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});


    const handleFormChanges = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                console.log(errors)
                setFormHasError(true);
            });
    }, []);

    const handleFinishFailed = useCallback(async (errorInfo) => {
        setSubmitClicked(true);
        // Set the blurred field to the first field with an error
        if (errorInfo.errorFields.length > 0) {
            setBlurredField('all');
        }
    }, [blurredField, isSubmitClicked]);

    const handleOnblurField = useCallback((field) => {
        if (!isSubmitClicked) {
            setBlurredField(field);
        }
        setSubmitClicked(false);

        const fieldError = form.getFieldError(field);
        setErrorMessages((prev) => ({
            ...prev,
            [field]: fieldError,
        }));

        // Trigger a re-render to ensure the error message is updated
        form.validateFields([field]).catch(() => {
            const updatedFieldError = form.getFieldError(field);
            setErrorMessages((prev) => ({
                ...prev,
                [field]: updatedFieldError,
            }));
        });
    }, [form, blurredField, isSubmitClicked]);

    const getMemberQuestionsRequest = useCallback(async () => {
            try {
                if (!spaceInfo) {
                    console.log('Space not found!')
                    return;
                }
                //
                // if (!authUserInfo) {
                // 	console.log('User not found!')
                // 	return;
                // }

                const data = {
                    space_id: spaceInfo.id,
                    user_id: authUserInfo.id
                };

                const response = await getMemberQuestions(data);
                // console.log(response);
                if (response && response.result) {
                    setMemberQuestions(response.member_questions);
                    setMemberDefaultQuestions(response.default_questions);
                    // setIsQuestionAnswered(response.is_question_answered);
                    setIsQuestionLoaded(true);
                } else {
                    setIsQuestionLoaded(true);
                }
            } catch (error) {
            }
        },
        [
            spaceInfo,
            authUserInfo
        ]
    );

    useEffect(() => {
        setSocialLinks(authUserInfo?.profile?.social_links)
    }, [authUserInfo])

    const skipActivation = useCallback(async () => {
        const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
        const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
        navigate(fin_url);
    }, [verifyEmailSpaceUsername, verifyEmailSpaceMembershipId]);


    // Get the Membership Details of the user
    const getMembershipDetailsQuery = useCallback(async () => {
        try {
            // console.log('getMembershipDetailsQuery')
            if (!spaceInfo) {
                return;
            }
            if (!authUserInfo) {
                return;
            }
            // console.log('params')
            const params = {};
            params.space_id = spaceInfo.id;
            params.user_id = authUserInfo.id;
            params.is_include_active_membership = true;
            const response = await getMembershipDetails(params);
            if (response && response.result) {
                const membershipDetails = response.membership_details;
                setMembershipDetails(membershipDetails);
                setIsNewAccountMembership(membershipDetails === null && getLocalStorage('redirect_link') !== null);
            }
        } catch (error) {
            console.log(error);
        }
    }, [spaceInfo, authUserInfo, membership]);

    const getMembership = useCallback(async (space_id, membership_id) => {
        const data = {
            space_id: space_id,
            membership_id: membership_id,
            is_get_payment_transaction: true
        }
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceMembership(data);
        if (response && response.result) {
            const membershipData = response.membership;
            setMembership(response.membership);
            if (membershipData.photo || membershipData.video) {
                if (membershipData.photo) {
                    setCollectionAssetType('image');
                }
                if (membershipData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                setCollectionAssetType('image');
            }
        }
    }, [authUserInfo]);

    const checkStatus = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id,
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await checkMembershipItemStatus(data);
        if (response) {
            setCheckStatusLoaded(true);
            if (response.result) {
                setCheckStatusLoaded(true);
                const transaction = response.stripe_transaction;
                if (transaction) {
                    if (transaction.status !== 'inactive') {
                        let url = routes.spaceMembershipBuy();
                        url = url.replace(':space_username', '@' + verifyEmailSpaceUsername);
                        url = url + '?id=' + verifyEmailSpaceMembershipId;
                        window.location.replace(url);
                        // navigate(fin_url);
                    }
                } else {
                    let url = routes.spaceMembershipBuy();
                    url = url.replace(':space_username', '@' + verifyEmailSpaceUsername);
                    url = url + '?id=' + verifyEmailSpaceMembershipId;
                    window.location.replace(url);
                    // navigate(fin_url);
                }
            }
            setCheckStatusLoaded(true);
        }
    }, [authUserInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId, currentLocation])


    const activateMembershipStatus = useCallback(async (isSwitchMembership = false) => {
        setSubmitted(true);
        if (!verifyEmailSpaceMembershipId) return;
        if (!spaceInfo) return;
        const data = {
            space_id: spaceInfo.id,
            membership_id: verifyEmailSpaceMembershipId,
            status: 'active'
        }

        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const createStripeResponse = await updateMembershipItemStatus(data);
        if (createStripeResponse && createStripeResponse.result) {
            setTimeout(() => {
                if (!isSwitchMembership) {
                    let url = routes.spaceMembershipBuy();
                    url = url.replace(':space_username', '@' + spaceInfo.username);
                    url = url + '?id=' + verifyEmailSpaceMembershipId;
                    window.location.replace(url);
                    // navigate(fin_url);
                }
            }, 500);

            setSubmitted(false);
        } else {
            setSubmitted(false);
        }
    }, [authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId])

    const keepActiveMembership = useCallback(async () => {
        setIsKeepActiveMembership(true);
    }, [])

    const switchNewMembership = useCallback(async () => {
        setIsSwitchMembership(true);
        activateMembershipStatus(true).then(r => {
        });
    }, [authUserInfo, spaceInfo, verifyEmailSpaceUsername, verifyEmailSpaceMembershipId])

    const redirectToMembers = useCallback(async () => {
        const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
        window.location.href = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '/member';
    }, [verifyEmailSpaceUsername])

    const redirectToDashboard = useCallback(async () => {
        const url = routes.spaces();
        window.location.replace(url);
        // navigate(url);
    }, [])

    const checkActivateSubscription = useCallback(async () => {
        const data = {
            space_id: spaceInfo.id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const checkActiveStatusResponse = await checkActiveMembershipStatus(data);
        if (checkActiveStatusResponse && checkActiveStatusResponse.result) {
            if (checkActiveStatusResponse.is_active_exist) {
                setActivationOpen(true);
            } else {
                await activateMembershipStatus();
            }
        } else {
            await activateMembershipStatus();
        }
    }, [spaceInfo, authUserInfo])

    // const handleFormChanges = useCallback(async () => {
    // 	setUsername(form.getFieldValue('username'));
    // 	setDisplayName(form.getFieldValue('display_name'));
    // 	setLocation(form.getFieldValue('location'));
    // 	setBio(form.getFieldValue('bio'));
    // 	setWebsite(form.getFieldValue('website'));
    //
    // 	await form
    // 		.validateFields()
    // 		.then(() => {
    // 			setFormHasError(false);
    // 		})
    // 		.catch((errors) => {
    // 			setFormHasError(true);
    // 		});
    // }, []);

    const parseObject = (obj) => {
        return Object.keys(obj).map((key) => ({question: key, answer: obj[key]}));
    };

    const handleSubmit = useCallback(async (values) => {
            setSubmitted(true);
            const params = {};
            params.member_user_id = authUserInfo.id;
            params.bio = values.bio;

            if (!memberDefaultQuestions || memberDefaultQuestions.length === 0) {
                await activateMembershipStatus();
            } else {
                try {
                    const parsedData = parseObject(values);
                    params.answers = `[${parsedData.map(item => JSON.stringify(item)).join(', ')}]`
                    const response = await answerMemberQuestion(params);
                    if (response && response.result) {
                        await activateMembershipStatus();
                    } else  {
                        setSubmitted(false);
                    }
                } catch (error) {
                    console.error('Error parsing data:', error.message);
                }
            }
        }, [spaceInfo, authUserInfo, membership, memberDefaultQuestions]
    )

    // Check and make a request for the getMembershipDetails
    useEffect(() => {
        if (spaceInfo && authUserInfo && membership && !isMembershipDetailsLoaded) {
            setMembershipDetailsLoaded(true);
            getMembershipDetailsQuery().then(r => {
            });
        }
    }, [spaceInfo, authUserInfo, membership, isMembershipDetailsLoaded])

    useEffect(() => {
        if (spaceInfo && !isQuestionsLoaded) {
            getMemberQuestionsRequest().then(() => {
            });
        }
    }, [spaceInfo, isQuestionsLoaded])

    useEffect(() => {
        if (verifyEmailSpaceUsername) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getMembership(spaceInfo.id, verifyEmailSpaceMembershipId).then((r) => {
                    });
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(verifyEmailSpaceUsername));
                }
            }
        }

        if (spaceInfo && verifyEmailSpaceMembershipId && authUserInfo) {
            checkStatus(spaceInfo.id, verifyEmailSpaceMembershipId).then(r => {
            });
        }
        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);
        // console.log(authUserInfo);
    }, [
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        initialLoad,
        verifyEmailSpaceUsername,
        verifyEmailSpaceMembershipId,
    ]);

    // useEffect(() => {
    //     if (membershipDetails) {
    //         if ((membershipDetails && membership && membership?.payment_transaction?.status === 'inactive') && !membershipDetails?.active_membership) {
    //             form.setFieldsValue({
    //                 community_bio: membershipDetails.community_bio ?? '',
    //                 favorite_food: membershipDetails.favorite_food ?? '',
    //                 favorite_city: membershipDetails.favorite_city ?? '',
    //                 favorite_spirit: membershipDetails.favorite_spirit ?? '',
    //                 food_allergies_restrictions: membershipDetails.favorite_spirit ?? ''
    //             });
    //         }
    //     }
    // }, [membershipDetails])

    useEffect(() => {

        memberQuestions.forEach((question, index) => {
            form.setFieldValue(question.id, question.answer);
            const questionField = question.id;
            setErrorMessages((prev) => ({
                ...prev,
                [questionField]: form.getFieldError(questionField),
            }));
        });

        memberDefaultQuestions.forEach((question, index) => {
            form.setFieldValue(question.id, question.answer);
        });
    }, [form, memberQuestions]);

    useEffect(() => {
    }, [
        membership,
        isKeepActiveMembership,
        isSwitchMembership,
        isSubmitted,
        isCheckStatusLoaded
    ])

    const renderQuestion = (form, handleOnblurField, blurredField, memberQuestion, index) => {
        return (
            <div className="form-input air" key={index}>
                <label htmlFor={memberQuestion.question_text}>{memberQuestion.question_text}</label>
                <Form.Item
                    label={memberQuestion.question_text}
                    name={memberQuestion.id}
                    validateTrigger="onBlur"
                    className="w-full"
                    rules={[
                        {required: memberQuestion.is_required},
                        {
                            max: 256,
                            message: 'Must not exceed 256 characters.'
                        }
                    ]}
                >
                    <FormFieldStacked
                        formType='input'
                        type="text"
                        maxLength={256}
                        onBlur={() => {
                            handleOnblurField(memberQuestion.id)
                        }}
                        placeholder={`${memberQuestion.question_help_text} ${memberQuestion.is_required ? ' Required.' : ' Optional.'}`}
                    />
                </Form.Item>
                {((blurredField === memberQuestion.id || blurredField === 'all') && form.getFieldsError()[index]?.errors.length > 0) && (
                    <div className="alert callout warning">
                        <div className="action">
                            <i className="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div className="content">
                            <div>
                                <h6>{form.getFieldsError()[index]?.errors[0]}</h6>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    let secondIndex = 1;
    let thirdIndex = 0;


    return (
        <>
            {(!authUserInfo || !membership) ? (
                <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                    <div className="centerframe-flex frame-modal">
                        <div className="block-lg modal-block">
                            <i className="fa-regular fa-solar-system"/>
                        </div>
                    </div>
                </section>
            ) : (
                <main id="cs-space-main" className="cs-main main-theme">
                    {(!spaceInfo || !authUserInfo) ? (
                        <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                            <div className="centerframe-flex frame-modal">
                                <div className="block-lg modal-block">
                                    <i className="fa-regular fa-solar-system"/>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <>
                            {/*  Create your member profile here for the newly created account from signup buy */}
                            {isNewAccountMembership ? (
                                <CreateMemberProfile
                                    isNewAccountFromPurchase={true}
                                    authUserInfo={authUserInfo}
                                    spaceInfo={spaceInfo}
                                    membership={membership}
                                />
                            ) : (
                                <section id="cs-product-membership-activate-c1" className="p-section-safeview theme-transparent">
                                    <div className="centerframe-grid gap-2gutter">
                                        {/*
                                            fam-space-product-membership-activate-new
                                            Note: Add the flagging here to detect the newly activated membership
                                        */}

                                        {/*
                                            fam-space-product-membership-activate-existing-inactive
                                            Note: Add the flagging here
                                        */}

                                        <>
                                            {/*{(!membershipDetails || (membershipDetails && membership && membership?.payment_transaction?.status === 'inactive') && !membershipDetails?.active_membership) && (*/}
                                            {(!membershipDetails || (membershipDetails && membership && membership?.payment_transaction?.status === 'inactive') && !membershipDetails?.active_membership) && (
                                                <>
                                                    <div id="cs-product-activate-main" className="block-lg order-1">
                                                        <hgroup className="heading">
                                                            {(membership && !membership?.payment_transaction && !membershipDetails?.active_membership) && (
                                                                <>
                                                                    {/*CSJS "Membership" or "Item" based on the product type */}
                                                                    <h1>Welcome to {membership?.name ?? ''}!</h1>
                                                                    {/* CSJS "Membership" or "Item" based on the product type */}
                                                                    <p className="text-p2">Complete your Member Details below to activate your membership.</p>
                                                                </>
                                                            )}

                                                            {(membership && membership?.payment_transaction?.status === 'inactive') && (
                                                                <>
                                                                    {/*CSJS "Membership" or "Item" based on the product type */}
                                                                    <h1>Welcome to {membership?.name ?? ''}!</h1>
                                                                    {/* CSJS "Membership" or "Item" based on the product type */}
                                                                    <p className="text-p2">Review and update your Member Details below to activate your membership.</p>
                                                                </>
                                                            )}

                                                        </hgroup>

                                                        <div className="cs-product-preview-row">
                                                            <div className="avatar-square-sm"><img src={membership?.photo ?? ''} alt="Image of Friends & Mike Membership"/></div>
                                                            <hgroup>
                                                                {/*CSJS Product Name*/}
                                                                <h5>{membership?.name ?? ''}</h5>
                                                                {/*CSJS Product Info Summary*/}
                                                                <p>{membership?.summary ?? ''}</p>
                                                            </hgroup>
                                                        </div>

                                                        <Form className="stack-col"
                                                              form={form}
                                                              name="form"
                                                              validateMessages={VALIDATE_MESSAGES}
                                                              onFocus={() => {
                                                                  // setFormIsOnFocus(true);
                                                              }}
                                                              onBlur={() => {
                                                                  // setFormIsOnFocus(false);
                                                                  // handleSignUpFormOnBlur().then(() =>{});
                                                              }}
                                                              autoComplete="off"
                                                              onChange={handleFormChanges}
                                                              onFinishFailed={handleFinishFailed}
                                                              onFinish={handleSubmit}
                                                        >

                                                            {(memberDefaultQuestions && memberDefaultQuestions.length > 0) &&
                                                                <>
                                                                    <div className="cs-stack-space-create-community-profile stack-col">
                                                                        <hgroup className="title">
                                                                            {/*CSJS "Friends & Mike" is space_name*/}
                                                                            <h3>{spaceInfo?.name ?? ''}{' '}Member Details</h3>
                                                                            <p>Share additional details on your Profile, visible to Members only.</p>
                                                                        </hgroup>

                                                                        {/*<hgroup className="heading">*/}
                                                                        {/*    <h3>Community Bio</h3>*/}
                                                                        {/*    <p>This is shown on your Profile and only visible to <a href="#" target="_blank">{spaceInfo.name}</a> Members.</p>*/}
                                                                        {/*</hgroup>*/}

                                                                        {(memberDefaultQuestions.map((memberQuestion, index) =>
                                                                                memberQuestion.is_visible && (
                                                                                    <div className="form-textarea air" key={index}>
                                                                                        <label htmlFor="shortbio">{memberQuestion.question_text}</label>
                                                                                        <Form.Item
                                                                                            label="Short Bio"
                                                                                            name={memberQuestion.id}
                                                                                            validateTrigger="onBlur"
                                                                                            className="w-full"
                                                                                            rules={[
                                                                                                {
                                                                                                    max: 512,
                                                                                                    message: 'Must not exceed 512 characters.'
                                                                                                }
                                                                                            ]}
                                                                                        >
                                                                                            <FormFieldStacked
                                                                                                formType='textarea'
                                                                                                type="text"
                                                                                                maxLength={512}
                                                                                                // disabled={submitted}
                                                                                                placeholder="Optional, up to 512 characters including spaces."
                                                                                                rows={3}
                                                                                            ></FormFieldStacked>
                                                                                        </Form.Item>
                                                                                        {form.getFieldsError()[index]?.errors.length > 0 && (
                                                                                            <div className="alert callout warning">
                                                                                                <div className="action">
                                                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                                                </div>
                                                                                                <div className="content">
                                                                                                    <div>
                                                                                                        <h6>{form.getFieldsError()[index]?.errors}</h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                        ))}

                                                                        {(memberQuestions && memberQuestions.length > 0) && memberQuestions.map((memberQuestion) => memberQuestion.is_visible && (
                                                                                renderQuestion(form, handleOnblurField, blurredField, memberQuestion, secondIndex++)
                                                                            )
                                                                        )}




                                                                        {/*<div className="form-textarea air">*/}
                                                                        {/*	<label htmlFor="community-bio">Community Bio</label>*/}
                                                                        {/*	/!*CSJS Default Community Field (Always Present), Friends & Mike is space_name. Values are filled with Member's existing data if not new.*!/*/}
                                                                        {/*	/!*<textarea id="community-bio" name="community-bio" placeholder="Share details about yourself with Friends & Mike members." rows="3"/>*!/*/}
                                                                        {/*	<Form.Item*/}
                                                                        {/*		name="community_bio"*/}
                                                                        {/*		validateTrigger="onBlur"*/}
                                                                        {/*		className="w-full"*/}
                                                                        {/*		rules={[*/}
                                                                        {/*			// {*/}
                                                                        {/*			// 	max: 32,*/}
                                                                        {/*			// 	message: 'Must not exceed 32 characters.'*/}
                                                                        {/*			// }*/}
                                                                        {/*		]}*/}
                                                                        {/*	>*/}
                                                                        {/*		<FormFieldStacked*/}
                                                                        {/*			formType='textarea'*/}
                                                                        {/*			name="community_bio"*/}
                                                                        {/*			// id="community-bio"*/}
                                                                        {/*			type="text"*/}
                                                                        {/*			// maxLength={32}*/}
                                                                        {/*			disabled={isSubmitted}*/}
                                                                        {/*			placeholder="Share details about yourself with Friends & Mike members."*/}
                                                                        {/*		/>*/}
                                                                        {/*	</Form.Item>*/}
                                                                        {/*</div>*/}


                                                                        {/*<div className="form-input air">*/}
                                                                        {/*	<label htmlFor="favorite-food">Favorite Food</label>*/}
                                                                        {/*	/!*<input type="text" name="favorite-food" id="favorite-food" required placeholder="What is your favorite food of all time?"/>*!/*/}
                                                                        {/*	<Form.Item*/}
                                                                        {/*		name="favorite_food"*/}
                                                                        {/*		validateTrigger="onBlur"*/}
                                                                        {/*		className="w-full"*/}
                                                                        {/*		rules={[*/}
                                                                        {/*			// {*/}
                                                                        {/*			// 	max: 32,*/}
                                                                        {/*			// 	message: 'Must not exceed 32 characters.'*/}
                                                                        {/*			// }*/}
                                                                        {/*		]}*/}
                                                                        {/*	>*/}
                                                                        {/*		<FormFieldStacked*/}
                                                                        {/*			formType='input'*/}
                                                                        {/*			name="favorite_food"*/}
                                                                        {/*			// id="favorite-food"*/}
                                                                        {/*			type="text"*/}
                                                                        {/*			// maxLength={32}*/}
                                                                        {/*			disabled={isSubmitted}*/}
                                                                        {/*			placeholder="What is your favorite food of all time?"*/}
                                                                        {/*		/>*/}
                                                                        {/*	</Form.Item>*/}
                                                                        {/*</div>*/}
                                                                        {/*<div className="form-input air">*/}
                                                                        {/*	<label htmlFor="favorite-city">Favorite City</label>*/}
                                                                        {/*	/!*<input type="text" name="favorite-city" id="favorite-city" required placeholder="What is your favorite city to visit?"/>*!/*/}
                                                                        {/*	<Form.Item*/}
                                                                        {/*		name="favorite_city"*/}
                                                                        {/*		validateTrigger="onBlur"*/}
                                                                        {/*		className="w-full"*/}
                                                                        {/*		rules={[*/}
                                                                        {/*			// {*/}
                                                                        {/*			// 	max: 32,*/}
                                                                        {/*			// 	message: 'Must not exceed 32 characters.'*/}
                                                                        {/*			// }*/}
                                                                        {/*		]}*/}
                                                                        {/*	>*/}
                                                                        {/*		<FormFieldStacked*/}
                                                                        {/*			formType='input'*/}
                                                                        {/*			name="favorite_city"*/}
                                                                        {/*			// id="favorite-city"*/}
                                                                        {/*			type="text"*/}
                                                                        {/*			// maxLength={32}*/}
                                                                        {/*			disabled={isSubmitted}*/}
                                                                        {/*			placeholder="What is your favorite city to visit?"*/}
                                                                        {/*		/>*/}
                                                                        {/*	</Form.Item>*/}
                                                                        {/*</div>*/}
                                                                        {/*<div className="form-input air">*/}
                                                                        {/*	<label htmlFor="favorite-spirit">Favorite Spirit</label>*/}
                                                                        {/*	/!*<input type="text" name="favorite_spirit" id="favorite-spirit" required placeholder="What is your favorite type of alcoholic spirit? (Optional)"/>*!/*/}
                                                                        {/*	<Form.Item*/}
                                                                        {/*		name="favorite_spirit"*/}
                                                                        {/*		validateTrigger="onBlur"*/}
                                                                        {/*		className="w-full"*/}
                                                                        {/*		rules={[*/}
                                                                        {/*			// {*/}
                                                                        {/*			// 	max: 32,*/}
                                                                        {/*			// 	message: 'Must not exceed 32 characters.'*/}
                                                                        {/*			// }*/}
                                                                        {/*		]}*/}
                                                                        {/*	>*/}
                                                                        {/*		<FormFieldStacked*/}
                                                                        {/*			formType='input'*/}
                                                                        {/*			name="favorite_spirit"*/}
                                                                        {/*			// id="favorite-spirit"*/}
                                                                        {/*			type="text"*/}
                                                                        {/*			// maxLength={32}*/}
                                                                        {/*			disabled={isSubmitted}*/}
                                                                        {/*			placeholder="What is your favorite type of alcoholic spirit? (Optional)"*/}
                                                                        {/*		/>*/}
                                                                        {/*	</Form.Item>*/}
                                                                        {/*</div>*/}


                                                                    </div>

                                                                    <div className="cs-stack-space-create-community-private-info stack-col">

                                                                        {(memberQuestions && memberQuestions.length) > 0 &&
                                                                            <hgroup className="heading">
                                                                                <h3>{spaceInfo?.name ?? ''} Private Member Information</h3>
                                                                                <p>Complete the following member information fields. This information is kept private and only visible to {spaceInfo?.name ?? ''} staff.</p>
                                                                            </hgroup>
                                                                        }

                                                                        {memberQuestions && memberQuestions.length > 0 && memberQuestions.map((memberQuestion, index) =>
                                                                                !memberQuestion.is_visible && (
                                                                                    renderQuestion(form, handleOnblurField, blurredField, memberQuestion, secondIndex + thirdIndex++)
                                                                                    // <div className="form-input air" key={index}>
                                                                                    // 	<label htmlFor={'hidden_question_' + (index + 1)}>
                                                                                    // 		{memberQuestion.question_text}*
                                                                                    // 	</label>
                                                                                    // 	<Form.Item
                                                                                    // 		label={memberQuestion.question_text}
                                                                                    // 		name={'hidden_question_' + (index + 1)}
                                                                                    // 		validateTrigger="onBlur"
                                                                                    // 		className="w-full"
                                                                                    // 		rules={[
                                                                                    // 		    { required: true },
                                                                                    // 		    {
                                                                                    // 		        max: 256,
                                                                                    // 		        message: 'Must not exceed 256 characters.'
                                                                                    // 		    }
                                                                                    // 		]}
                                                                                    // 	>
                                                                                    // 		<FormFieldStacked
                                                                                    // 			formType="input"
                                                                                    // 			type="text"
                                                                                    // 			maxLength={256}
                                                                                    // 			onBlur={()=>{handleOnblurField('hidden_question_'+(index + 1))}}
                                                                                    // 			placeholder={`${memberQuestion.question_help_text} ${memberQuestion.is_required ? 'Required.' : 'Optional.'}`}
                                                                                    // 		/>
                                                                                    // 	</Form.Item>
                                                                                    // </div>
                                                                                )
                                                                        )}
                                                                        {/*<hgroup className="title">*/}
                                                                        {/*	/!*CSJS "Friends & Mike" is space_name*!/*/}
                                                                        {/*	<h3>{membership?.name ?? ''} Member Information</h3>*/}
                                                                        {/*	/!*CSJS "Friends & Mike" is space_name*!/*/}
                                                                        {/*	<p>Complete the following member information fields. This information is kept private and only visible to {membership?.name ?? ''} staff.</p>*/}
                                                                        {/*</hgroup>*/}

                                                                        {/*<div className="form-input air">*/}
                                                                        {/*	<label htmlFor="food-allergies-restrictions">Food Allergies & Restrictions</label>*/}
                                                                        {/*	/!*<input type="text" name="food-allergies-restrictions" id="food-allergies-restrictions" required placeholder="Let us know if you have any allergies or dietary restrictions."/>*!/*/}
                                                                        {/*	<Form.Item*/}
                                                                        {/*		name="food_allergies_restrictions"*/}
                                                                        {/*		validateTrigger="onBlur"*/}
                                                                        {/*		className="w-full"*/}
                                                                        {/*		rules={[*/}
                                                                        {/*			// {*/}
                                                                        {/*			// 	max: 32,*/}
                                                                        {/*			// 	message: 'Must not exceed 32 characters.'*/}
                                                                        {/*			// }*/}
                                                                        {/*		]}*/}
                                                                        {/*	>*/}
                                                                        {/*		<FormFieldStacked*/}
                                                                        {/*			formType='input'*/}
                                                                        {/*			name="food_allergies_restrictions"*/}
                                                                        {/*			// id="food-allergies-restrictions"*/}
                                                                        {/*			type="text"*/}
                                                                        {/*			// maxLength={32}*/}
                                                                        {/*			disabled={isSubmitted}*/}
                                                                        {/*			placeholder="Let us know if you have any allergies or dietary restrictions."*/}
                                                                        {/*		/>*/}
                                                                        {/*	</Form.Item>*/}
                                                                        {/*</div>*/}
                                                                    </div>

                                                                </>
                                                            }


                                                            <Form.Item>
                                                                <div className="stack-buttons col">
                                                                    {/*CSJS Can use a button instead of an A element by changing element from "a" to "button" and removing "button-" from class.*/}
                                                                    <button
                                                                        // disabled={submitted}
                                                                        type="submit"
                                                                        className="primary-gradient">
                                                                        <span><p>
                                                                            {(isSubmitted) && (
                                                                                <i className="fa-solid fa-spinner fa-spin !mr-2 !text-2xl"/>
                                                                            )}
                                                                            Save & Activate</p></span>
                                                                    </button>
                                                                </div>
                                                            </Form.Item>
                                                        </Form>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                        {/* END of fam-space-product-membership-activate-new */}

                                        {/*
										fam-space-product-membership-activate-existing-confirmation-switch
										Note: Add the flagging here
									*/}
                                        {(membership && membershipDetails && membershipDetails?.active_membership && !isKeepActiveMembership && !isSwitchMembership && membership.id !== membershipDetails?.active_membership?.id) && (
                                            <>
                                                <div id="cs-product-activate-main" className="block-lg order-1">
                                                    <hgroup className="heading">
                                                        {/* CSJS "Membership" or "Item" based on the product type*/}
                                                        <h1>Existing Active Membership</h1>
                                                        {/*CSJS "Membership" or "Item" based on the product type*/}
                                                        <p className="text-p2">Your currently have another <a href={`@${authUserInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a>
                                                            {' '}Membership active. You can only have one Membership active at a time.
                                                        </p>
                                                    </hgroup>

                                                    <div className="cs-stack-title-preview">
                                                        <h4>Active Membership</h4>
                                                        <div className="cs-product-preview-row">
                                                            <div className="avatar-square-gradient-md"><img src={membershipDetails?.active_membership?.photo ?? ''} alt={membershipDetails?.active_membership?.name ?? ''}/></div>
                                                            <hgroup>
                                                                {/*CSJS Product Name*/}
                                                                <h5>{membershipDetails?.active_membership?.name ?? ''} Membership</h5>
                                                                {/*CSJS Product Info Summary*/}
                                                                <p>{membershipDetails?.active_membership?.summary ?? ''}</p>
                                                            </hgroup>
                                                        </div>
                                                    </div>

                                                    <div className="cs-stack-title-preview">
                                                        <h4>New Membership</h4>
                                                        <div className="cs-product-preview-row">
                                                            <div className="avatar-square-gradient-md"><img src={membership?.photo ?? ''} alt={membership?.name ?? ''}/></div>
                                                            <hgroup>
                                                                {/*CSJS Product Name*/}
                                                                <h5>{membership?.name ?? ''} Membership</h5>
                                                                {/*CSJS Product Info Summary*/}
                                                                <p>{membership?.summary ?? ''}</p>
                                                            </hgroup>
                                                        </div>
                                                    </div>

                                                    <div className="stack-col">
                                                        <div className="cs-stack-space-create-community-profile stack-col">
                                                            <hgroup className="title">
                                                                <h3>Select Membership</h3>
                                                                <p>Please select which Membership you would like to use as as your active Membership. If you choose to change Memberships, your existing Membership will be deactivated and your selected Membership will be activated. Deactivated Memberships are not lost and can always be reactivated from your Dashboard.</p>
                                                            </hgroup>

                                                        </div>
                                                        <div className="stack-buttons col">
                                                            <button onClick={keepActiveMembership} className="secondary-gradient"><span><p>Keep Active Membership</p></span></button>
                                                            <button onClick={switchNewMembership} className="secondary-gradient"><span><p>Switch to New Membership</p></span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {/*
										END of fam-space-product-membership-activate-existing-confirmation-switch
									*/}
                                        {/*
										Start of fam-space-product-membership-activate-existing-confirmation-keep
									*/}
                                        {isKeepActiveMembership && (
                                            <>
                                                <div id="cs-product-activate-main" className="block-lg order-1">
                                                    <hgroup className="heading">
                                                        {/* CSJS "Membership" or "Item" based on the product type*/}
                                                        <h1>No Changes Made</h1>
                                                        {/*CSJS "Membership" or "Item" based on the product type*/}
                                                        <p className="text-p2">Your current <a href={`@${authUserInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"></i> {spaceInfo?.name ?? ''}</a>
                                                            {' '}Membership has been kept active. No changes have been made to your Items.
                                                        </p>
                                                    </hgroup>

                                                    <div className="cs-stack-title-preview">
                                                        <h4>Active Membership</h4>
                                                        <div className="cs-product-preview-row">
                                                            <div className="avatar-square-gradient-md"><img src={membershipDetails?.active_membership?.photo ?? ''} alt={membershipDetails?.active_membership?.name ?? ''}/></div>
                                                            <hgroup>
                                                                {/*CSJS Product Name*/}
                                                                <h5>{membershipDetails?.active_membership?.name ?? ''} Membership</h5>
                                                                {/*CSJS Product Info Summary*/}
                                                                <p>{membershipDetails?.active_membership?.summary ?? ''}</p>
                                                            </hgroup>
                                                        </div>
                                                    </div>

                                                    <div className="cs-stack-title-preview">
                                                        <h4>New Membership <span>Inactive</span></h4>
                                                        <div className="cs-product-preview-row">
                                                            <div className="avatar-square-gradient-md"><img src={membership?.photo ?? ''} alt={membership?.name ?? ''}/></div>
                                                            <hgroup>
                                                                {/*CSJS Product Name*/}
                                                                <h5>{membership?.name ?? ''} Membership</h5>
                                                                {/*CSJS Product Info Summary*/}
                                                                <p>{membership?.summary ?? ''}</p>
                                                            </hgroup>
                                                        </div>
                                                    </div>

                                                    <p className="text-p2">You can always manage your Memberships and Items in your Dashboard.</p>

                                                    <button onClick={redirectToMembers} className="primary-gradient"><span><p>Go to Members</p></span></button>

                                                </div>
                                            </>
                                        )}

                                        {/*
										Start of fam-space-product-membership-activate-existing-confirmation-switch
									*/}
                                        {isSwitchMembership && (
                                            <>
                                                <div id="cs-product-activate-main" className="block-lg order-1">
                                                    <hgroup className="heading">
                                                        <h1>New Membership Activated</h1>
                                                        <p className="text-p2">Your new <a href={`@${authUserInfo?.username ?? ''}`} target="_blank"><i className="fa-regular fa-solar-system"/> {spaceInfo?.name ?? ''}</a>
                                                            {' '}Membership is now active and your previous Membership has been deactivated. No changes have been made to your Items.
                                                        </p>
                                                    </hgroup>

                                                    <div className="cs-stack-title-preview">
                                                        <h4>New Membership Active</h4>
                                                        <div className="cs-product-preview-row">
                                                            <div className="avatar-square-gradient-md"><img src={membership?.photo ?? ''} alt={membership?.name ?? ''}/></div>
                                                            <hgroup>
                                                                {/*CSJS Product Name*/}
                                                                <h5>{membership?.name ?? ''} Membership</h5>
                                                                {/*CSJS Product Info Summary*/}
                                                                <p>{membership?.summary ?? ''}</p>
                                                            </hgroup>
                                                        </div>
                                                    </div>

                                                    <div className="cs-stack-title-preview">
                                                        <h4>Existing Membership <span>Inactive</span></h4>
                                                        <div className="cs-product-preview-row">
                                                            <div className="avatar-square-gradient-md"><img src={membershipDetails?.active_membership?.photo ?? ''} alt={membershipDetails?.active_membership?.name ?? ''}/></div>
                                                            <hgroup>
                                                                {/*CSJS Product Name*/}
                                                                <h5>{membershipDetails?.active_membership?.name ?? ''} Membership</h5>
                                                                {/*CSJS Product Info Summary*/}
                                                                <p>{membershipDetails?.active_membership?.summary ?? ''}</p>
                                                            </hgroup>
                                                        </div>
                                                    </div>

                                                    <p className="text-p2">You can always manage your Memberships and Items in your Dashboard.</p>

                                                    <div className="stack-buttons col">
                                                        <button onClick={redirectToDashboard} className="primary-gradient"><span><p>View in Dashboard</p></span></button>
                                                        <button onClick={redirectToMembers} className="secondary-gradient"><span><p>Go to Members</p></span></button>
                                                    </div>

                                                </div>
                                            </>
                                        )}


                                        <aside id="cs-profile-create-aside" className="block-lg order-2 hidden lg:block">
                                            {/*CSJS BG Image as inline style background.*/}
                                            <div className="cs-profile-preview card sticky top-2gutter-xl"
                                                 style={{backgroundImage: `url("${authUserInfo?.profile?.background_photo ?? ''}")`}}
                                            >
                                                {/*CSJS Add class "shader" if background-image is applied in parent element cs-profile-preview, i.e when a user has uploaded a background.*/}
                                                <div className="cs-stack-profile-preview rounded-card stack-col shader">
                                                    {/*CSJS Image as inline style background.*/}
                                                    <div className="form-input-media lg circle air nohover"
                                                         style={{backgroundImage: `url("${authUserInfo?.profile?.photo_thumb ?? ''}")`}}
                                                    >
                                                        <a href="#">
                                                        </a>
                                                    </div>
                                                    <hgroup>
                                                        {/*CSJS Add "active" class when content present.*/}
                                                        <p className="text-p1 active">@{authUserInfo?.profile?.username ?? 'username'}</p>
                                                        <h1>{authUserInfo?.profile?.display_name ?? 'Display Name'}</h1>
                                                        <p className="text-p2 italic">
                                                            {authUserInfo?.profile?.pronouns ?? 'Pronouns'}
                                                        </p>
                                                    </hgroup>
                                                    <p className="italic">{authUserInfo?.profile?.intro ?? 'Short Bio goes here'}</p>
                                                    {(authUserInfo?.profile) && (
                                                        <SocialLinks
                                                            socialLinks={authUserInfo.profile.social_links}
                                                            parentClassName="cs-stack-icons stack-row-wrap"
                                                            childClassName=""
                                                            removeATag={true}
                                                        />
                                                    )}
                                                    {/*<div className="cs-stack-icons stack-row-wrap">*/}
                                                    {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                                    {/*	<i className="fa-brands fa-x-twitter"/>*/}
                                                    {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                                    {/*	<i className="fa-brands fa-instagram"/>*/}
                                                    {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                                    {/*	<i className="fa-brands fa-facebook-f"/>*/}
                                                    {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                                    {/*	<i className="fa-brands fa-tiktok"/>*/}
                                                    {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                                    {/*	<i className="fa-brands fa-linkedin-in"/>*/}
                                                    {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                                    {/*	<i className="fa-brands fa-twitch"/>*/}
                                                    {/*	/!*CSJS Only show if exists for User Profile.*!/*/}
                                                    {/*	<i className="fa-regular fa-globe"/>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </aside>
                                    </div>


                                    {/*<div className="cs-centerframe-split-lg">*/}
                                    {/*    <div className="cs-centerframe-split-left-lg order-2 lg:order-1">*/}
                                    {/*        <div className="p-section-safeview lg:pr-gutter">*/}
                                    {/*            <div className="cs-stack-checkout-form stack-col-base gap-gutterxx lg:max-w-half-breakpoint-less-gutter">*/}
                                    {/*                <div className="cs-stack-checkout-confirmation stack-col">*/}
                                    {/*                    <hgroup className="heading">*/}
                                    {/*                        <h2>Your Membership is ready!</h2>*/}
                                    {/*                    </hgroup>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="cs-stack-checkout-activate stack-col">*/}
                                    {/*                    <hgroup className="heading">*/}
                                    {/*                        <p>You’re ready to access{' '}*/}
                                    {/*							{membership ? membership.name : ''}! You can activate*/}
                                    {/*							your Membership on your Account now or you can activate it later.*/}
                                    {/*							You won’t get access to the Membership Benefits until you do.</p>*/}
                                    {/*                    </hgroup>*/}

                                    {/*                    <div className="cs-product-preview-row">*/}
                                    {/*                        <div className="avatar-square-sm">*/}
                                    {/*                        <img src={membership?.photo} alt="Image of Friends & Mike Membership"/>*/}
                                    {/*                        </div>*/}
                                    {/*                        <hgroup>*/}
                                    {/*                            <h5>{' '}{spaceInfo ? spaceInfo.name : ''} Membership</h5>*/}
                                    {/*                            <p>The Official {' '}{spaceInfo ? spaceInfo.name : ''} Membership unlocks global benefits and community.</p>*/}
                                    {/*                        </hgroup>*/}
                                    {/*                    </div>*/}

                                    {/*                    <div className="stack-buttons col">*/}
                                    {/*                        <a onClick={checkActivateSubscription} type="button" className="button-primary-gradient"><span><p>Continue to Activate</p></span></a>*/}
                                    {/*                        <a onClick={skipActivation} type="button" className="button-secondary-gradient"><span><p>Skip Activation</p></span></a>    */}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="cs-centerframe-split-right-lg order-1 lg:order-2 lg:pt-gutter lg:pb-gutter">*/}
                                    {/*    */}
                                    {/*        <div className="cs-checkout-product-hero">*/}
                                    {/*            */}
                                    {/*            {membershipItemImage(collectionAssetType, membership)}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*{ membershipDetails &&*/}
                                    {/*	<ActivationMembershipModal*/}
                                    {/*		modalOpen={isActivationOpen}*/}
                                    {/*		setModalOpen={setActivationOpen}*/}
                                    {/*		membership={membershipDetails}*/}
                                    {/*		space={spaceInfo}*/}
                                    {/*		user={authUserInfo}*/}
                                    {/*		activationStatus={'active'}*/}
                                    {/*		isPaymentMethod={true}*/}
                                    {/*		redirectionPath={redirectionPath}*/}
                                    {/*	/>*/}
                                    {/*}*/}
                                </section>
                            )}
                        </>
                    )}
                </main>
            )}
        </>
    );
};

export default memo(MembershipActivateWalletConnect);
